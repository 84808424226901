<template>
    <div>
        <el-dialog
        title="添加标签"
        :close-on-click-modal="false" 
        :visible.sync="disableHidden">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标签名称" prop="tagName">
                    <el-input v-model="ruleForm.tagName" placeholder="请输入标签名称" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvents">取 消</el-button>
                <el-button size="small" type="primary" @click="onSubmits">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { dictAPI } from 'liankong-ui-api' 
export default {
    data() {
        return {
            disableHidden: false,
            ruleForm: {
                tagName: ''
            },
            rules: {
                tagName: [
                    { required: true, message: '标签名称不能为空', trigger: 'blur' }
                ]
            },
        }
    },
    methods: {
        init(){
            this.ruleForm.tagName = '';
        },
        onSubmits(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    dictAPI.addTagData({
                        tagName: this.ruleForm.tagName
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success('添加成功');
                            this.cancleEvents(); 
                            // 刷新列表 && 刷新左菜单
                            this.$emit('refreshList')
                        } else {
                            this.$message.error(res.message);
                            this.cancleEvents(); 
                        }
                    }).catch(error=>{
                            this.$message.error(error.message);
                            this.cancleEvents(); 
                    })
                }
            })
        },
        cancleEvents(){
            this.disableHidden = false;
        },
    }
}
</script>
<template>
    <div>
        <div class="tag-span-wraps">
            <h2>自定义标签</h2>
            <p>
                支持自定义添加标签，为文档分配标签后，快速查找对应文档数据并在线预览
            </p>

            <div>
                <el-button type="primary" round icon="el-icon-plus" size="medium" @click="addTagBtn">添加标签</el-button>
                <el-button round icon="el-icon-delete" size="medium" @click="removeTagBtn">删除标签</el-button>
            </div>
        </div>
        <div class="tag-span-list">
            <el-row :gutter="20">
                <el-col :span="4" v-for="(item,index) in tagData" :key="index" @click.native="handleTagSpanClickEvent(item,index)">
                    <i :class="item.icon"></i>
                    <span>{{item.tagName}}</span>
                    <img src="@/assets/select.png" alt="" class="select" v-if="item.selected"/>
                </el-col>
            </el-row>
        </div>
        <el-pagination background @current-change="pageChanged" layout="prev, pager, next" :page-size="query.pageSize"
            :total="total">
        </el-pagination>

        <AddtagType ref="tagTypeDialog" @refreshList="refreshList"></AddtagType>
    </div>
</template>
<script>
import { dictAPI } from 'liankong-ui-api' 
import AddtagType from './components/AddtagType.vue'

export default {
    data(){
        return {
            total: 0,
            query: {
                pageNum: 1,
                pageSize: 10
            },
            selectDatas: []
        }
    },
    components: {
        AddtagType
    },
    inject: ["editRefreshLeftMenu"],
    computed: {
        // 文档分类->我的标签
        tagData(){
            let tagArr = this.$store.state.dict.tagList;
            tagArr.map(tagItem=>{
                this.$set(tagItem, 'selected', false)
            })
            return tagArr;
        }
    },
    mounted(){
        
    },
    methods: {
        // 添加
        addTagBtn(){
            this.$refs.tagTypeDialog.disableHidden = true;
            this.$nextTick(()=>{
                this.$refs.tagTypeDialog.init();
            })
        },
        // 删除
        removeTagBtn(){
            // 如果都没有选中的
            let isSelectArray = []
            this.tagData.map(fileItems=>{
                isSelectArray.push(fileItems.selected)
            })
            if(isSelectArray.indexOf(true) == -1) {
                this.selectDatas = []
            }
            
            if(this.selectDatas.length == 0) {
                this.$message.error('请选择数据')
                return
            } else if(this.selectDatas.length > 1){
                this.$message.error('只能选择一条数据');
                return;
            }
           
            dictAPI.removeTagData(this.selectDatas[0].id).then(res => {
                if(res.code == 0) {
                    this.$message.success('删除成功');

                    this.$store.dispatch('dict/getTagList', this.query);
                    // 刷新左菜单
                    this.editRefreshLeftMenu();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(error=>{
                this.$message.error(error.message)
            })
        },
        // 分页
        pageChanged(num) {
            this.query.pageNum = num;
            this.$store.dispatch('dict/getTagList', this.query)
        },
        // 刷新列表 && 刷新左菜单
        refreshList(){
            this.$store.dispatch('dict/getTagList', this.query)
            this.editRefreshLeftMenu();
        },
        // 选中
        handleTagSpanClickEvent(item,index) {
            this.selectDatas = [];
            this.tagData.map(dataItem=>{
                if(dataItem.id == item.id) {
                    dataItem.selected = !dataItem.selected
                } else {
                    dataItem.selected = false
                }
            })
            this.selectDatas.push(item);
        }
    }
}
</script>
<style lang="less" scoped>
.tag-span-wraps {
    width: 100%;
    height: 280px;
    background-image: url('../../assets/span-banner.png');
    background-size: 100% 100%;
    h2 {
        padding: 50px 0 0 50px;
    }
    p {
        width: 500px;
        padding: 0 50px 0 50px;
        font-size: 16px;
        line-height: 28px;
    }
    div {
        display: flex;
        margin: 20px 0 0 50px;
        .el-button.el-button--primary {
            margin-right: 5px;
        }
    }
}
.tag-span-list {
    margin-top: 20px;
    .el-row {
        margin: 0px !important;
        .el-col {
            border: 1px solid #DCE5EF;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
            margin: 0 20px 20px 0;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            i {
                font-size: 30px;
                margin-right: 10px;
            }
            span {
                font-size: 17px;
                font-weight: 600;
            }
            img {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .el-col:hover {
            border: 1px solid #409EFF;
            color: #409EFF;
            box-shadow: 0px 2px 14px 0px rgba(18, 55, 100, 0.2);
        }
    }
}
</style>